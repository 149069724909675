export const fontSizeOptions = [
  '0.75rem',
  '0.875rem',
  '1rem',
  '1.125rem',
] as const

export const setBodyFontSize = (sizePreference: number) => {
  const size = fontSizeOptions[sizePreference]

  if (!size) {
    return
  }

  document.body.style.fontSize = size
}
